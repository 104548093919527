<template>
<div>
  <main>
    <div>
      <error-data v-if="isErrorDataSet"/>
      <div v-else>
        <order-details-top :transaction="transaction"
                           :visibility="visibilityOptions"
                           :show-button="false" />
        <div class="container">
          <h1 class="page-title_hidden" id="page-title-for-sr">{{ $t('sessionTimeout.pageTitle') }}</h1>
          <div class="columns confirmation">
            <section class="sidebar-column">
              <order-details-side :transaction="transaction"
                                  :visibility="visibilityOptions"
                                  :show-button="false" />
            </section>
            <section class="main-column">
              <div class="content confirmation-wrapper">
                <div class="outdated">
                  <h2 class="normal" v-html="$t('sessionTimeout.header')"></h2>
                  <p v-html="$t('sessionTimeout.description')"></p>
                </div>
                <session-timeout-back-button />
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </main>
    </div>
</template>

<script>
import { pageTitle } from '@/base/const/routing.const'
import { mapGetters, mapMutations, mapState } from 'vuex'
import OrderDetailsSide from '@/base/components/OrderDetails/Side.vue'
import OrderDetailsTop from '@/base/components/OrderDetails/Top'
import SessionTimeoutBackButton from '@/base/components/Payment/SessionTimeout/BackButton'
import { ERROR_CODES_NAMES } from '@/base/const/errorCodes.const'
import ErrorData from '@/base/components/Error/Data'

export default {
  name: 'SessionTimeout',
  metaInfo () {
    return {
      title: pageTitle().base,
      titleTemplate: '%s | ' + pageTitle().sessionTimeout
    }
  },
  components: {
    SessionTimeoutBackButton,
    OrderDetailsSide,
    OrderDetailsTop,
    ErrorData
  },
  computed: {
    ...mapState({
      transaction: state => state.payment.paymentData?.transaction?.orderId ? state.payment.paymentData.transaction : null,
      visibilityOptions: state => state.payment.paymentData ? state.payment.paymentData.visibilityOptions : null
    }),
    ...mapGetters({
      isErrorDataSet: 'isErrorDataSet'
    })
  },
  methods: {
    ...mapMutations([
      'SET_ERROR_DATA',
      'SET_PAYMENT_PAYWALL_VIEW'
    ])
  },
  mounted () {
    if (!this.transaction) {
      this.SET_PAYMENT_PAYWALL_VIEW(false)
      this.SET_ERROR_DATA({
        errorCode: ERROR_CODES_NAMES.SESSION_TIMEOUT,
        errorUrl: null
      })
    }
  }
}

</script>
