<template>
  <p>
    <button type="button"
            class="btn button-back-to-shop"
            :title="$t('error.linkBackUrlSet')"
            @click="goBackToShop">
      <span>{{ $t('error.linkBackUrlSet') }}</span>
    </button>
  </p>
</template>

<script>
import { mapState } from 'vuex'
import { ToolsClass } from '@/base/class/tools.class'
import { MobileAppClass } from '@/base/class/mobileApp.class'

export default {
  name: 'SessionTimeoutBackButton',
  computed: {
    ...mapState({
      returnUrl: state => state.payment.paymentData.returnUrl
    })
  },
  methods: {
    goBackToShop () {
      const url = this.returnUrl
      if (ToolsClass.isMobileAppConnection()) {
        MobileAppClass.callMobileTransactionFinish()
        return
      }
      ToolsClass.goToExternalUrl(url)
    }
  }
}
</script>
